<template>
    <modal
        class="creator-program"
        :adaptive="true"
        :scrollable="true"
        name="creator-program"
        width="694"
        height="auto"
    >
        <div class="header">
            <div class="title-container">
                <h3>Creator Program</h3>
                <span>Terms and conditions</span>
            </div>
            <img
                src="@assets/img/icons/x.svg"
                alt="X"
                width="14px"
                @click="$modal.hide('creator-program')"
            >
        </div>

        <div class="body">
            <div v-html="terms" />
        </div>

        <div class="footer">
            <button class="btn btn-primary" @click="apply">
                Apply
            </button>
        </div>
    </modal>
</template>

<script>
import terms from "@assets/terms/conditions.html";

export default {
    name: "CreatorProgramModal",
    data: () => ({ terms }),
    methods: {
        async apply() {
            const { data: { url } } = await axiosV2.post("/founders-program");
            this.$modal.hide("creator-program");
            
            return window.location.href = url;
        }
    }
}
</script>

<style lang="scss" scoped>
.creator-program {

    .header {
        position: relative;

        .title-container {
            display: flex;
            flex-direction: column;
        }

        h3 {
            text-align: center;
            font-weight: normal;
            font-size: 26px;
            margin-bottom: 26px;
        }

        span {
            font-size: 20px;
            line-height: 24px;
            text-align: center;
        }

        img {
            position: absolute;
            top: 5px;
            right: 0;
            cursor: pointer;
        }
    }

    .body {
        overflow-y: scroll;
        max-height: 568px;
        margin-top: 20px;
        background: #2C2C2E;
        border-radius: 12px;
        padding: 12px 24px;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0;
    }


    /deep/ .vm--modal {
        background-color: #111111;
        border-radius: 12px;
        box-shadow: none;
        padding: 10px 20px;
    }
}
</style>