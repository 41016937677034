<template>
    <modal
        class="email-change"
        :adaptive="true"
        :scrollable="true"
        name="email-change"
        width="366"
        height="auto"
    >
        <div class="header">
            <div class="title-container">
                <h3>Email Profile Updated</h3>
                <p class="content">
                    Your email address has been updated.<br>
                    Please sign in using your new email address.
                </p>
            </div>
        </div>

        <div class="footer">
            <button class="btn btn-primary" @click="logout">
                Ok
            </button>
        </div>
    </modal>
</template>

<script>
export default {
    name: "EmailChangeModal",
    methods: {
        async logout() {
            this.$amplitude.logEvent("Logout");
            this.$emit("is-loading", true);

            await this.$store.dispatch("Application/resetGlobalData");

            await this.$router.push({ name: "landing" });
            window.location.reload();
        }
    }
}
</script>

<style lang="scss" scoped>
.email-change {

    .header {
        position: relative;

        .content {
            font-size: 16px;
            text-align: center;
        }

        .title-container {
            display: flex;
            flex-direction: column;
        }

        h3 {
            text-align: center;
            font-weight: normal;
            font-size: 26px;
            margin-bottom: 26px;
        }

        span {
            font-size: 20px;
            line-height: 24px;
            text-align: center;
        }

        img {
            position: absolute;
            top: 5px;
            right: 0;
            cursor: pointer;
        }
    }

    .body {
        overflow-y: scroll;
        max-height: 568px;
        margin-top: 20px;
        background: #2C2C2E;
        border-radius: 12px;
        padding: 12px 24px;
    }

    .footer {
        display: flex;
        justify-content: center;
        margin: 20px 0;
    }


    /deep/ .vm--modal {
        background-color: #111111;
        border-radius: 12px;
        box-shadow: none;
        padding: 10px 20px;
    }
}
</style>